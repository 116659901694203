/* compiled from /scss/_modules.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";


.m_csr_title {
  padding-top: 50px;
  @include mq(tbl) {
    padding-top: 95px;
  }
  .inner {
    padding: 0 20px 12px;
    @include mq(tbl) {
     padding: 0 20px;
    }
    .lead {
      @include futogo;
      @include fs20;
      text-align: center;
      @include mq(tbl) {
        @include fs(38,68);
      }
      + .desc {
        margin-top: 40px;
      }
    }
    .desc {
      @include futogo;
      @include fs15;
      text-align: left;
      @include mq(tbl) {
        width: 690px;
        margin: 0 auto;
      }
    }
  }
}
.m_csr_main {
  margin-top: 50px;
  @include mq(tbl) {
    margin-top: 60px;
    padding-bottom: 150px;
  }
  .inner {
    padding-left: 20px;
    padding-right: 20px;
    @include mq(tbl) {
      padding-left: calc(50% - 480px);
      padding-right: calc(50% - 480px);
    }
    h2 {
      border-left: 1px solid #8BCFDE;
      border-bottom: 1px solid #8BCFDE;
      padding: 18px 0 17px 25px;
      color: $main;
      @include fs20;
      margin-bottom: 35px;
      margin-top: 50px;
      @include mq(tbl) {
        margin-bottom: 55px;
        margin-top: 70px;
      }
    }
    p, ul {
      @include fs15;
      @include mq(tbl) {
        padding: 0 22px;
      }
    }
    .shadow {
      background-color: $white;
      margin-top: 35px;
      box-shadow: 0 0 12px rgba(169,202,206,.24);
      padding: 35px 25px;
      @include mq(tbl) {
        margin-top: 45px;
        padding: 45px 110px 65px;
      }
      h3 {
        text-align: center;
        color: $main;
        @include fs(18,18);
        margin-bottom: 45px;
        span {
          border-bottom: 2px solid $main;
          padding-bottom: 7px;
          display: inline-block;
          @include fs(20,20);
        }
      }
      h4 {
        @include fs(17,32);
        margin-top: 40px;
        margin-bottom: 5px;
        @include mq(tbl) {
          @include fs20;
        }
      }
      p {
        @include mq(tbl) {
          padding: 0;
        }
      }
      ul {
        list-style: disc;
        @include mq(tbl) {
          padding: 0;
        }
        li {
          margin-left: 1em;
        }
      }
    }
    .col {
      @include mq(tbl) {
        margin-top: 10px;
        display: grid;
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-template-columns: repeat(2, 1fr);
        gap: 10px 40px;
      }
    }
    .organization {
      margin-top: 40px;
      img {
        &.sp {
          @include mq(tb) {
            display: none;
          }
        }
        &.pc {
          display: none;
          @include mq(tb) {
            display: block;
          }
        }
      }
    }
    .meeting {
      background-color: #E3F0F0;
      border-radius: 10px;
      padding: 40px 36px;
      margin-top: 40px;
      @include fs15;
      @include mq(tbl) {
        padding: 40px 50px;
        border-radius: 20px;
        display: grid;
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 60px;
      }
      .unit {
        margin-top: 35px;
        @include mq(tbl) {
          margin-top: 0;
        }
        &:first-of-type {
          margin-top: 0;
        }
        h3 {
          @include futogo;
          &:first-letter {
            color: $main;
          }
          &.small {
            @include fs(13,21);
            @include mq(sp) {
              @include fs15;
            }
          }
        }
        p {
          margin-top: 6px;
          padding: 0;
        }
      }
    }
    .column {
      @include mq(tbl) {
        padding: 0 22px 90px;
        display: grid;
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-template-columns: repeat(2, 1fr);
        gap: 0 40px;
      }
      .unit {
        margin-top: 35px;
        padding-bottom: 15px;
        @include mq(tbl) {
          margin-top: 0;
          padding-bottom: 0;
        }
        h3 {
          @include fs(18,29);
          @include futogo;
          margin-bottom: 35px;
          @include mq(tbl) {
            @include fs(24,38);
          }
        }
        p {
          @include mq(tbl) {
            padding: 0;
          }
        }
        .button {
          margin-top: 30px;
          @include companybutton;
          a {
            margin: 0 auto
          }
          span {
            @include fs(17,17);
            @include mq(tbl) {
              @include fs(18,18);
            }
          }
        }
        .image {
          margin-top: 35px;
          text-align: center;
          @include mq(tbl) {
            margin-top: 0;
          }
          img {
            max-width: 450px;
            @include mq(tbl) {
              width: 332px;
            }
          }
        }
      }
    }
  }
}