/* compiled from /scss/_modules.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";

.m_privacy {
  margin-top: 35px;
  @include mq(tbl) {
  }
  .inner {
    padding: 0 22px;
    @include mq(tbl) {
      @include container(narrow);
    }
    h1 {
      display: none;
      @include mq(tbl) {
        display: block;
        @include futogo;
        @include fs20;
        padding-bottom: 35px;
        border-bottom: 1px solid #8BCFDE;
      }
    }
    h2 {
      margin-top: 100px;
      color: $main;
      @include fs(18,26);
      &:first-of-type {
        margin-top: 0;
        @include mq(tbl) {
          margin-top: 35px;
        }
      }
      @include mq(tbl) {
        @include fs20;
      }
    }
    p, ul, ol, dl {
      @include fs15;
    }
    p {
      margin-top: 35px;
    }
    dl {
      dt {
        margin-top: 35px;
        @include futogo;
      }
      dd {
        padding-top: .5em;
        padding-left: 1.4em;
        word-break: break-all;
      }
    }
    ol {
      li {
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}