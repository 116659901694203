/* compiled from /scss/_modules.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";

.m_mv {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  .inner {
    padding: 0 20px;
    @include mq(tbl) {
      @include container(contents);
    }
  }
}
.m_sp_title {
  margin-top: 10px;
  @include mq(tbl) {
    display: none;
  }
  .inner {
    padding: 0 30px 22px;
    h1 {
      text-align: center;
      @include fs20;
    }
  }
}
.m_title_a {
  margin-top: 50px;
  .inner {
    @include container(contents);
    text-align: center;
    @include mq(tb) {
      padding-left: calc(50% - 345px);
      padding-right: calc(50% - 345px);
    }
    h1 {
      @include fs(16,28);
      @include futogo;
    }
    p {
      @include fs(12,18);
      @include en;
    }
  }
}
.m_text_a {
  margin-top: 40px;
  .inner {
    @include container(contents);
    @include mq(tb) {
      padding-left: calc(50% - 345px);
      padding-right: calc(50% - 345px);
    }
    h2 {
      @include futogo;
      @include fs20;
      text-align: center;
      @include mq(tbl) {
        @include fs30;
      }
    }
    p {
      margin-top: 30px;
      @include fs15;
      @include mq(tbl) {
        margin-top: 50px;
      }
      + p {
        margin-top: 15px;
        @include mq(tbl) {
          margin-top: 25px;
        }
      }
      &.ceo {
        text-align: right;
        @include futogo;
        span {
          font-size: 1.7rem;
        }
      }
    }
  }
}