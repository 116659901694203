/* compiled from /scss/_footer.scss */

@charset "UTF-8";
@import "variables";
@import "mixins";


footer {
  letter-spacing: 0;
  border-top: 1px solid #E6E6E6;
  margin-top: 60px;
  .recruit & {
    margin-top: 0;
  }
  @include mq(tbl) {
    letter-spacing: 1px;
    margin-top: 140px;
  }
  .top {
    padding: 77px 0 72px;
    position: relative;
    @include mq(tbl) {
      padding: 83px 0 130px;
    }
    &::before {
      @include pd;
      height: 388px;
      width: 100%;
      background: white center top / cover no-repeat url(/images/bg_footer.png);
      bottom: 0;
      z-index: -1;
      @include mq(tb) {
        height: auto;
        top: 0;
        background-image: url(/images/bg_footer_pc.png);
      }
    }
    a {
      color: $black;
    }
    .inner {
      padding: 0 37px;
      @include mq(tbl) {
        @include container(normal);
      }
      .logo {
        text-align: center;
        @include mq(tbl) {
          text-align: left;
          padding-left: 74px;
        }
        @include mq(pc) {
          padding: 0;
        }
        img {
          width: 300px;
          @include mq(tbl) {
            width: 398px;
          }
        }
      }
      .footersitemap {
        margin-top: 50px;
        @include mq(tbl) {
          margin-top: 92px;
          display: flex;
          gap: 22px;
          justify-content: space-between;
          padding-left: 74px;
          padding-right: 74px;
        }
        @include mq(pc) {
          gap: 42px;
          padding: 0;
        }
        .unit {
          margin-top: 30px;
          @include mq(tbl) {
            margin-top: 0;
            flex: 1;
          }
          h2 {
            padding-bottom: 8px;
            border-bottom: 2px solid $main-light;
            @include fs(15,15);
            @include futogo;
            position: relative;
            @include mq(tbl) {
              @include bbb;
            }
            &.acc {
              &::after {
                @include pd;
                width: 0;
                height: 0;
                border-style: solid;
                right: 6px;
                top: 2px;
                border-width: 7px 5px 0 5px;
                border-color: #ccc transparent transparent transparent;
                transition: transform $transition-speed-fast $bezier;
                @include mq(tbl) {
                  content: none;
                }
              }
              &.active {
                &::after {
                  transform: rotate(-90deg);
                }
              }
            }
          }
          > ul {
            padding-top: 14px;
            @include fs(14,21);
            display: none;
            @include mq(tbl) {
              display: block !important;
              @include fs(12,18);
            }
            li {
              &.haschild {
                a {
                  padding-bottom: 10px;
                }
              }
              a {
                display: block;
                padding: 16px 12px;
                @include mq(tbl) {
                  padding: 10px 0;
                  color: #4d4d4d;
                }
              }
              ul {
                li {
                  a {
                    padding: 8px 24px;
                    @include mq(tbl) {
                      padding: 5px 22px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    #pageTop {
      margin-top: 50px;
      text-align: right;
      position: relative;
      @include en;
      @include fs(10,10);
      letter-spacing: 1px;
      @include mq(tbl) {
        position: absolute;
        right: 0;
        top: -1px;
        margin-top: 0;
      }
      &:before {
        @include pd;
        height: 2px;
        width: 120px;
        background-color: #b3b3b3;
        right: 0;
        top: 0;
        @include mq(tbl) {
          background-color: #ccc;
        }
      }
      a {
        color: $gray;
        display: inline-block;
        mix-blend-mode: multiply;
        padding: 18px 58px 0 7px;
      }
    }
  }
  .bottom {
    background-color: #4D4D4D;
    padding: 35px 0 27px;
    text-align: center;
    @include mq(tbl) {
      padding: 20px 0;
      text-align: left;
    }
    .inner {
      @include container(normal);
      @include mq(tbl) {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
      ul {
        @include mq(tbl) {
          display: flex;
          gap: 22px;
          padding-right: 74px;
        }
        @include mq(pc) {
          padding-right: 0;
        }
        li {
          &.sitemap {
            display: none;
            @include mq(tbl) {
              display: block;
            }
          }
          &.en {
            display: none;
          }
          a {
            color: #ccc;
            @include fs(13,13);
            display: block;
            padding: 15px 0;
            @include mq(tbl) {
              @include fs(10,10);
              padding: 0;
            }
          }
        }
      }
      .search {
        margin-top: 35px;
        @include mq(tbl) {
          display: none;
        }
        .searchinner {
          border: 1px solid #ddd;
          padding: 2px;
          background-color: $white;
          width: 242px;
          margin: 0 auto;
          input {
            width: 205px;
            @include fs(16,16);
            vertical-align: bottom;
          }
          button {
            margin: 0;
            img {
              width: 26px;
            }
          }
        }
      }
      .copyright {
        margin-top: 50px;
        text-align: center;
        @include en;
        color: #ccc;
        @include fs(9,9);
        @include mq(tbl) {
          margin-top: 0;
          @include fs(10,10);
          text-align: left;
          padding-left: 74px;
        }
        @include mq(pc) {
          padding-left: 0;
        }
      }
    }
  }
}
