/* compiled from /scss/_modules.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";

.m_business_title {
  margin-top: 35px;
  padding: 0 22px;
  opacity: 0;
  transition: opacity 2s ease .5s;
  &.loaded {
    opacity: 1;
  }
  @include mq(tbl) {
    margin-top: 70px;
  }
  .inner {
    background-color: $white;
    padding: 48px 50px;
    max-width: 730px;
    margin: 0 auto;
    box-shadow: 0 0 12px rgba(169,202,206,.24);
    @include mq(tbl) {
      padding: 70px 68px 70px;
    }
    .tagline {
      border-top: 1px solid $main;
      border-bottom: 1px solid $main;
      padding: 13px 0;
      display: flex;
      justify-content: center;
      gap: 11px;
      @include mq(tbl) {
        padding: 19px 0;
        gap: 15px;
      }
      span {
        @include fs(17,17);
        @include futogo;
        color: $main;
        @include mq(tbl) {
          @include fs(26,26);
        }
        &.by {
          img  {
            width: 13px;
            vertical-align: baseline;
            @include mq(tbl) {
              width: 19px;
            }
          }
        }
      }
    }
    h1 {
      margin-top: 20px;
      @include fs(25,42);
      @include futogo;
      text-align: center;
      @include mq(tbl) {
        margin-top: 30px;
        @include fs(38,64);
      }
    }
  }
}
.m_business {
  margin-top: 50px;
  @include mq(tbl) {
    margin-top: 200px;
  }
  .inner {
    .unit {
      margin-top: 60px;
      @include inview;
      @include mq(tbl) {
        margin-top: 100px;
        display: flex;
        align-items: center;
      }
      &:first-of-type {
        margin-top: 0;
      }
      &:nth-of-type(even){
        @include mq(tbl) {
          flex-direction: row-reverse;
        }
        .image {
          text-align: right;
        }
        .text {
          .textinner {
            margin-left: auto;
          }
        }
      }
      .image {
        text-align: left;
        @include mq(tbl) {
          flex: 1;
        }
        img {
          max-width: 600px;
          width: 80vw;
          @include mq(tbl) {
            max-width: none;
            width: 100%;
          }
        }
      }
      .text {
        margin-top: 50px;
        padding: 0 30px;
        @include mq(tbl) {
          flex: 1;
          margin-top: 0;
          padding: 0;
        }
        .textinner {
          @include mq(tbl) {
            padding: 0 45px;
            width: 510px;
          }
          @include mq(pc) {
            width: 525px;
          }
          .genre {
            @include fs(16,16);
            @include futogo;
            color: $main;
            @include mq(tbl) {
              @include fs(18,18);
            }
          }
          h2 {
            @include fs20;
            @include futogo;
            margin-top: 12px;
            @include mq(tbl) {
              @include fs30;
            }
          }
          .desc {
            margin-top: 25px;
            p {
              @include fs15;
              @include mq(tbl) {
              }
            }
            .button {
              @include companybutton;
            }
          }
        }
      }
    }
  }
}