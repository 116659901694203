/* compiled from /scss/_modules.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";

.m_contact {
  margin-top: 35px;
  .inner {
    padding: 0 22px;
    @include mq(tbl) {
      @include container(narrow);
    }
    .title {
      display: none;
      @include mq(tbl) {
        display: block;
        @include futogo;
        @include fs20;
      }
    }
    .thanks {
      text-align: center;
      background-color: $white;
      padding: 35px 25px;
      margin: 0 auto;
      box-shadow: 0 0 12px rgba(169,202,206,.24);
      @include mq(tbl) {
        padding: 50px 112px;
        margin-top: 35px;
      }
      p {
        @include fs15;
      }
    }
    .contact_01 {
      background-color: $white;
      padding: 35px 25px 0;
      margin: 0 auto;
      box-shadow: 0 0 12px rgba(169,202,206,.24);
      @include mq(tbl) {
        padding: 50px 112px 0;
        margin-top: 35px;
      }
      table:first-of-type {
        &.confirm {
          tr {
            &:first-child {
              th {
                padding-top: 0;
              }
              td {
                @include mq(tbl) {
                  padding-top: 0;
                }
              }
            }
          }
        }
        tr {
          &:first-child {
            th, td {
              border-top: none;
            }
          }
        }
      }
    }
    h3 {
      @include futogo;
      @include fs(18,24);
      margin-top: 60px;
      @include mq(tbl) {
        @include fs20;
        margin-top: 100px;
      }
    }
    .contact_02 {
      margin-top: 40px;
      border-bottom: 1px solid #8BCFDE;
      table {
        &.confirm {
          tr {
            th:not(.block) {
              @include mq(tbl) {
                padding-top: 30px;
                padding-bottom: 30px;
              }
            }
            td:not(.block) {
              @include mq(tbl) {
                padding-top: 30px;
                padding-bottom: 30px;
              }
            }
          }
        }
        tr {
          th {
            @include mq(tbl) {
              &:not(.block) {
                padding-left: 30px;
                padding-top: 45px;
                width: 30%;
              }
            }
          }
          td {
            @include mq(tbl) {
              &:not(.block) {
                width: 70%;
                padding: 30px 0 ;
              }
            }
          }
        }
      }
    }
    table {
      width: 100%;
      &.confirm {
        tr {
          th, td {
            padding: 25px 3px;
            @include mq(tbl) {
              padding: 40px 0;
            }
          }
          td {
            padding-top: 0;
            @include fs15;
            @include mq(tbl) {
              padding-top: 40px;
            }
            ul {
              li {
                &::before {
                  content: '\30FB';
                }
              }
            }
          }
        }
      }
      tr {
        th, td {
          display: block;
          padding: 25px 3px;
          @include mq(tbl) {
            padding: 35px 0 40px;
            &:not(.block){
              display: table-cell;
              vertical-align: top;
            }
          }
        }
        th {
          @include futogo;
          @include fs(15,30);
          border-top: 1px solid #8BCFDE;
          padding-bottom: 0;
          @include mq(tbl) {
            &:not(.block){
              width: 40%;
              padding-bottom: 40px;
            }
          }
          .require {
            display: inline-block;
            line-height: 1;
            padding: 0.4em 0.8em;
            color: $white;
            background-color: $main;
            text-align: center;
            border-radius: 6px;
            margin-left: 10px;
            font-size: 1.3rem;
          }
        }
        td {
          @include mq(tbl) {
            padding: 25px 0 40px;
            &:not(.block){
              width: 60%;
              border-top: 1px solid #8BCFDE;
            }
          }
          label.error {
            color: $red;
            display: block;
            margin-top: 10px;
          }
          input, select, textarea {
            font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif !important;
            @include fs(16,22);
            width: 100%;
            border: solid 1px #d9d9d9;
            border-radius: 6px;
            color: #333;
            background-color: #fff;
            padding: 0.6em;
            @include mq(tbl) {
              padding: 1em 16px;
              @include fs(15,20);
            }
            &:disabled {
              background-color: #f5f5f5;
            }
            &#zip1 {
              width: 70px;
            }
            &#zip2 {
              width: 90px;
            }
            &[name="name"],&[name="kana"],&[name="address1"]  {
              @include mq(tbl) {
                width: 443px;
              }
            }
          }
          textarea {
            resize: vertical;
            @include mq(tbl) {
              @include fs(15,30);
            }
          }
          select {
            appearance: none;
            background-image: url(/images/ic_select.svg);
            background-repeat: no-repeat;
            background-position: center right 15px;
            padding-right: 60px;
            width: 100%;
            &[name="pref"]{
              @include mq(tbl) {
                width: 443px;
              }
            }
          }
          ul.how {
            @include mq(tbl) {
              margin: 10px 0 0 40px;
            }
            li {
              margin-bottom: 2em;
              @include mq(tbl) {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
              }
              .how-head {
                position: relative;
                padding-bottom: 1em;
                @include mq(tbl) {
                  display: flex;
                  align-items: center;
                  width: 30%;
                  @include fs(15,15);
                }
              }
              .how-body {
  
                @include mq(tbl) {
                  width: 65%;
                }
              }
            }

          }
          
          input[type=checkbox], input[type=radio] {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            + label:before {
              display: inline-block;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url(/images/ic_checkbox.svg);
              transform: translate(0, 5px);
              margin-right: 10px;
            }
            &:checked {
              + label:before {
                background-position: center right;
              }
            }
          }
          .guide {
            margin-top: 0.5em;
            @include fs(13,21);
          }
          .gender {
            display: flex;
            @include mq(tbl) {
              padding: 10px 0 20px;
            }
            li {
              position: relative;
              margin-right: 50px;
            }
          }
          .h-4 {
            @include futogo;
            margin-bottom: .5em;
            @include mq(tbl) {
              padding: 14px 0 10px;
              @include fs(15,15);
            }
          }
          .mt-2 {
            margin-top: 3rem !important;
          }
        }
      }
    }
    .button {
      margin-top: 40px;
      text-align: center;
      @include mq(tbl) {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .back {
        margin-bottom: 60px;
        padding-top: 10px;
        @include mq(tbl) {
          padding-top: 20px;
        }
        a {
          color: $black;
          display: inline-block;
          padding: 0 12px 8px;
          border-bottom: 1px solid $main;
        }
      }
      button[type="submit"]{
        cursor: pointer;
        @include futogo;
        appearance: none;
        border: solid 1px $main;
        width: 100%;
        font-size: 1.8rem;
        line-height: 76px;
        border-radius: 38px;
        text-align: center;
        max-width: 390px;
        background-color: #fff;
        color: $black;
        @include mq(tbl) {
          font-size: 2.2rem;
          @include hoverOp;
        }
      }
    }
    
  }
}