/* compiled from /scss/_modules.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";

.m_recruit_mv {
  background: $white center top / contain no-repeat url(/images/recruit_01.jpg);
  padding-top: 63vw ;
  text-align: center;
  @include mq(tbl) {
    height: 930px;
    padding-top: 150px;
    background-position: center bottom;
    background-size: cover;
  }
  + .n_spnav {
    background-color: $white;
  }
  .inner {
    padding: 0 20px 12px;
    @include mq(tbl) {
     padding: 0;
    }
    h1 {
      display: none;
      @include mq(tbl) {
        display: block;
        @include futogo;
        @include fs20;
        color: $main;
      }
    }
    .tagline {
      @include futogo;
      @include fs20;
      @include mq(tbl) {
        @include fs(38,64);
        margin-top: 20px;
      }
    }
    .desc {
      margin-top: 55px;
      @include fs15;
      text-align: left;
      box-shadow: 0 0 12px rgba(169,202,206,.24);
      padding: 15px 25px;
      opacity: 0;
      transition: opacity 2s ease .5s;
      &.loaded {
        opacity: 1;
      }
      @include mq(tbl) {
        background-color: rgba(255,255,255,.7);
        width: 812px;
        margin: 30px auto 0;
        padding: 35px 50px;
      }
    }
  }
}

.m_recruit_title {
  padding-top: 50px;
  text-align: center;
  @include mq(tbl) {
    padding-top: 150px;
  }
  + .n_spnav {
    background-color: $white;
  }
  &.about {
    opacity: 0;
    transition: opacity 2s ease .5s;
    &.loaded {
      opacity: 1;
    }
  }
  .inner {
    padding: 0 20px 12px;
    @include mq(tbl) {
     padding: 0;
    }
    h1 {
      display: none;
      @include mq(tbl) {
        display: block;
        @include futogo;
        @include fs20;
        color: $main;
      }
    }
    .tagline {
      display: none;
      @include mq(tbl) {
        display: block;
        @include fs(38,64);
        @include futogo;
        margin-top: 15px;
      }
    }
    .lead {
      @include futogo;
      @include fs20;
      @include mq(tbl) {
        margin-top: 50px;
        @include fs(24,42);
      }
    }
    .desc {
      @include futogo;
      @include fs15;
      text-align: left;
      margin-top: 10px;
      @include mq(tbl) {
        width: 690px;
        margin: 50px auto 0;
      }
      &.large {
        @include mq(tbl) {
          width: 960px;
        }
      }
    }
  }
}
.n_recruit_main {
  padding-top: 20px;
  @include mq(tbl) {
    padding-top: 80px;
  }
  .inner {
    @include container(contents);
    @include mq(tbl) {
      display: flex;
      justify-content: center;
      gap: 39px;
    }
    .unit {
      display: block;
      margin: 50px auto 0;
      max-width: 400px;
      text-align: center;
      color: $black;
      @include mq(tbl) {
        margin: 0;
      }
      &:first-of-type {
        margin-top: 0;
      }
      &:hover {
        @include mq(tbl) {
          opacity: 1;
          .image {
            img {
              transform: scale(1.05);
            }
          }
          .en, h2 {
            opacity: .5;
          }
        }
      }
      .image {
        overflow: hidden;
        img {
          transition: transform $transition-speed-fast $bezier;
        }
      }
      .en {
        @include fs15;
        @include en;
        margin-top: 30px;
        transition: opacity $transition-speed-fast $bezier;
      }
      h2 {
        @include fs(18,18);
        @include futogo;
        border-top: 2px solid $main-light;
        margin-top: 10px;
        padding-top: 18px;
        transition: opacity $transition-speed-fast $bezier;
      }
    }
  }
}
.n_recruit_sub {
  margin-top: 90px;
  padding: 32px 0;
  background: center center / cover no-repeat url(/images/recruit_05.jpg);
  @include mq(tbl) {
    background-image: url(/images/recruit_05_pc.jpg);
    margin-top: 100px;
    padding: 120px 0;
  }
  .inner {
    @include container(narrow);
    @include mq(tbl) {
      display: flex;
      justify-content: space-between;
    }
    .unit {
      display: block;
      margin: 16px auto 0;
      max-width: 438px;
      text-align: center;
      color: $black;
      background-color: $white;
      padding: 5px;
      @include mq(tbl) {
        flex: 1;
        margin: 0;
      }
      &:first-of-type {
        margin-top: 0;
      }
      &:hover {
        @include mq(tbl) {
          opacity: 1;
          h2, p {
            opacity: .5;
          }
        }
      }
      .unitinner {
        border: 1px solid #A9CACE;
        padding: 43px 25px 40px;
        h2 {
          @include fs20;
          @include futogo;
          transition: opacity $transition-speed-fast $bezier;
        }
        p {
          @include fs15;
          margin-top: 3px;
          display: inline-block;
          position: relative;
          padding-right: 20px;
          transition: opacity $transition-speed-fast $bezier;
          &::after {
            @include pd;
            background: center center / contain no-repeat url(/images/arrow_recruit.svg);
            width: 10px;
            height: 15px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
.m_recruit_about_01 {
  margin-top: 50px;
  @include mq(tbl) {
    margin-top: 220px;
  }
  .inner {
    padding: 0 16px;
    @include mq(tbl) {
      padding: 0;
    }
    .unit {
      display: block;
      margin-top: 50px;
      @include inview;
      @include mq(tbl) {
        display: flex;
        align-items: center;
        margin-top: 60px;
      }
      &:first-of-type {
        margin-top: 0;
        @include mq(tbl) {
          flex-direction: row-reverse;
        }
        .image {
          @include mq(tbl) {
            padding-right: 0;
            padding-left: 72px;
          }
        }
        .text {
          .textinner {
            margin-right: 0;
            margin-left: auto;
            padding: 0 20px 0 0;
          }
        }
      }
      .image {
        @include mq(tbl) {
          width: 50%;
          padding-right: 72px;
        }
      }
      .text {
        margin-top: 45px;
        padding: 0 15px;
        @include fs15;
        @include mq(tbl) {
          width: 50%;
          margin-top: 0;
          padding: 0;
        }
        .textinner {
          @include mq(tbl) {
            width: 435px;
            margin-right: auto;
            padding: 0 0 0 20px;
          }
          h2 {
            @include fs20;
            @include futogo;
            @include mq(tbl) {
              @include fs30;
            }
          }
          p {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
.m_recruit_about_02 {
  margin-top: 110px;
  @include mq(tbl) {
    padding-bottom: 45px;
  }
  .inner {
    .text {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        @include fs(21,45);
        @include futogo;
        overflow-x: hidden;
        @include mq(tbl) {
          @include fs(30,64);
        }
        &.underline {
          span {
            &::after {
              @include pd;
              bottom: 2px;
              left: 0;
              right: 0;
              height: 2px;
              background-color: $main-light;
              transform: translateX(-100%);
              transition: transform 1.2s ease-out 1s;
            }
          }
        }
        span {
          display: inline-block;
          position: relative;
        }
      }
      &.inview {
        p.underline {
          span {
            &::after {
              transform: translateX(0);
            }
          }
        }
      }
    }
    .image {
      margin-top: 90px;
      text-align: center;
      overflow: hidden;
      @include inview;
      img {
        height: 300px;
        object-fit: cover;
        @include mq(tbl) {
          height: 600px;
        }
      }
    }
  }
}
.m_recruit_skills {
  margin-top: 85px;
  @include mq(tbl) {
    margin-top: 170px;
    padding-bottom: 45px;
  }
  .unit {
    background-color: #90DDD8;
    &:nth-child(even){
      background-color: $white;
      .inner {
        .text {
          h2 {
            color: #90DDD8;
          }
        }
      }
    }
    .inner {
      @include mq(tb) {
        position: relative;
        height: 500px;
        display: flex;
        align-items: center;
      }
      @include mq(tbl) {
        height: 650px;
      }
      @include mq(pc) {
        height: 800px;
      }
      .image {
        @include mq(tb) {
          position: absolute;
          width: 100%;
        }
        img {
          object-fit: cover;
          object-position: right center;
          height: 106vw;
          @include mq(tb) {
            width: 100%;
            height: 500px;
            object-position: center center;
          }
          @include mq(tbl) {
            height: 650px;
          }
          @include mq(pc) {
            height: 800px;
          }
        }
      }
      .text {
        padding: 40px 30px 50px;
        @include mq(tb) {
          position: relative;
          z-index: 2;
          padding: 0 0 0 30px;
        }
        @include mq(tbl) {
          width: $container-narrow;
          margin: 0 auto;
          padding: 0 0 0 20px;
        }
        h2 {
          @include en;
          @include fs(46,52);
          color: #eee;
          overflow: hidden;
          display: inline-block;
          opacity: 0;
          @include mq(tbl) {
            @include fs(80,90);
          }
          &.slideAnimeLeftRight {
            animation-name:slideTextX100;
            animation-duration:1.2s;
            animation-fill-mode:forwards;
            opacity: 0;
          }
          @keyframes slideTextX100 {
            from {
            transform: translateX(-100%);
              opacity: 0;
            }
            to {
            transform: translateX(0);
              opacity: 1;
            }
          }
          span {
            display: inline-block;
            &.slideAnimeRightLeft {
              animation-name:slideTextX-100;
              animation-duration:1.2s;
              animation-fill-mode:forwards;
              opacity: 0;
            }
          }
          @keyframes slideTextX-100 {
            from {
            transform: translateX(100%);/*要素を右の枠外に移動*/
              opacity: 0;
            }
            to {
            transform: translateX(0);/*要素を元の位置に移動*/
              opacity: 1;
            }
          }
        }
        h3 {
          margin-top: 22px;
          @include fs20;
          @include futogo;
          @include mq(tbl) {
            @include fs30;
          }
        }
        p {
          @include fs15;
          margin-top: 30px;
          @include mq(tb) {
            width: 435px;
          }
        }
        .button {
          @include companybutton;
          a {
            margin-left: auto;
            margin-right: auto;
            @include mq(tb) {
              width: 435px;
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
.m_recruit_faq {
  @include mq(tbl) {
    margin-top: 90px;
    padding-bottom: 45px;
  }
  .inner {
    padding-left: 20px;
    padding-right: 20px;
    @include mq(tb) {
      padding-left: calc(50% - 365px);
      padding-right: calc(50% - 365px);
    }
    .unit {
      border-bottom: 1px solid #8BCFDE;
      padding: 28px 13px 25px;
      position: relative;
      @include mq(tbl) {
        padding: 50px 20px;
      }
      &:first-of-type {
        border-top: 1px solid #8BCFDE;
      }
      &:before, &:after {
        @include pd;
        background-color: $main;
        right: 12px;
        top: 30px;
        @include mq(tbl) {
          top: 60px;
        }
      }
      &:before {
        width: 19px;
        height: 1px;
        top: 39px;
        @include mq(tbl) {
          width: 29px;
          top: 66px;
          right: 21px;
        }
      }
      &:after {
        height: 19px;
        width: 1px;
        right: 21px;
        transition: transform $transition-speed-fast $bezier;
        @include mq(tbl) {
          top: 52px;
          height: 29px;
          right: 35px;
        }
      }
      &.active {
        &:after {
          transform: scaleY(0);
        }
      }
      h2 {
        color: $main;
        @include fs(16,38);
        cursor: pointer;
        @include mq(tbl) {
          @include fs(20,38);
          display: flex;
          align-items: center;
          @include hoverOp;
        }
        &::before {
          content: 'Q.';
          display: block;
          @include fs(20,20);
          @include futogo;
          margin-bottom: 10px;
          @include mq(tbl) {
            display: inline-block;
            @include fs(30,30);
            margin-bottom: 0;
            margin-right: 20px;
          }
        }
      }
      .a {
        padding-top: 30px;
        @include fs15;
        display: none;
        position: relative;
        @include mq(tbl) {
          padding-left: 55px;
        }
        &::before {
          content: 'A.';
          display: block;
          @include fs(20,20);
          @include futogo;
          margin-bottom: 10px;
          @include mq(tbl) {
            display: inline-block;
            position: absolute;
            @include fs(30,30);
            left: 3px;
          }
        }
      }
    }
  }
}
.n_recruit_outline {
  margin-top: 50px;
  @include mq(tbl) {
    padding-bottom: 45px;
    margin-top: 80px;
  }
  .inner {
    padding-left: 20px;
    padding-right: 20px;
    @include mq(tbl) {
      padding-left: calc(50% - 457px);
      padding-right: calc(50% - 457px);
    }
    .unit {
      background-color: $white;
      @include mq(tbl) {
        padding: 0 92px;
      }
      &.shadow {
        box-shadow: 0 0 12px rgba(169,202,206,.24);
        padding: 30px 25px 0;
        @include mq(tbl) {
          padding: 65px 92px 0;
        }
        + .shadow {
          margin-top: 50px;
          @include mq(tbl) {
            margin-top: 50px;
          }
        }
      }
      h2 {
        text-align: center;
        color: $main;
        @include fs(18,18);
        span {
          border-bottom: 2px solid $main;
          padding-bottom: 7px;
          display: inline-block;
          @include fs(20,20);
        }
      }
      h3 {
        background-color: #A9CACE;
        color: $white;
        @include fs(18,28);
        @include futogo;
        text-align: center;
        padding: 12px;
        margin-top: 50px;
        @include mq(tbl) {
          margin-top: 100px;
        }
      }
      .button {
        margin-top: 30px;
        a {
          display: block;
          text-align: center;
          max-width: 435px;
          border: 1px solid $main;
          text-align: center;
          padding: 18px;
          border-radius: 50px;
          color: $main;
          @include fs(17,17);
          transition: background  $transition-speed-fast $bezier, color  $transition-speed-fast $bezier;
          @include mq(tbl) {
            @include fs(18,18);
            margin: 50px 20px 0;
            max-width: none;
            padding: 30px;
            &:hover {
              color: white;
              opacity: 1;
              background-color: $main;
            }
          }
        }
      }
      p {
        @include fs15;
        margin-top: 30px;
        @include mq(tbl) {
          margin: 45px 20px 0;
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      table {
        tr {
          &:last-child {
            th, td {
              border-bottom: none;
            }
          }
          th {
            display: block;
            padding: 35px 0 15px;
            color: $main;
            @include fs(15,25);
            @include futogo;
            @include mq(tbl) {
              padding: 53px 0 53px 20px ;
              display: table-cell;
              border-bottom: 1px solid #8BCFDE;
              white-space: nowrap;
              width: 125px;
              vertical-align: top;
            }
          }
          td {
            display: block;
            border-bottom: 1px solid #8BCFDE;
            padding: 0 0 35px;
            @include mq(tbl) {
              padding: 45px 20px 45px 0;
              display: table-cell;
            }
            > p {
              @include fs(17,32);
              margin: 0;
              @include mq(tbl) {
                @include fs(20,38);
              }
              .small {
                @include fs15;
                display: inline-block;
                margin-top: 5px;
                @include mq(tbl) {
                }
              }
              + p {
                margin-top: 1.25em;
              }
            }
          }
        }
      }
      .goldnav {
        margin-top: 50px;
        border-top: 1px solid #8BCFDE;
        border-bottom: 1px solid #8BCFDE;
        padding: 30px 0 25px;
        @include mq(tbl) {
          margin: 90px 0 0;
          padding: 40px 20px;
          display: flex;
          align-items: center;
          gap: 27px;
        }
        .logo {
          text-align: center;
          img {
            width: 189px;
          }
        }
        p {
          margin-top: 25px;
          @include fs(12,22);
          color: #8E8E8E;
          @include mq(tbl) {
            margin: 0;
            flex: 0 1 auto;
          }
        }
      }
      .flow {
        margin-top: 60px;
        padding-bottom: 35px;
        @include mq(tbl) {
          margin-top: 80px;
          padding: 0 70px 50px;
          position: relative;
          &::before {
            @include pd;
            width: 4px;
            background-color: #8BCFDE;
            top: 30px;
            bottom: 70px;
            left: 123px;
          }
        }
        dl {
          margin-top: 35px;
          @include mq(tbl) {
            display: flex;
            gap: 37px;
            margin-top: 0;
            padding-top: 45px;
            align-items: center;
          }
          &:first-of-type {
            margin-top: 0;
            @include mq(tbl) {
              padding-top: 0;
            }
          }
          dt {
            span {
              display: flex;
              border: 1px solid #8BCFDE;
              border-radius: 50%;
              margin: 0 auto;
              width: 110px;
              height: 110px;
              align-items: center;
              justify-content: center;
              background-color: $white;
              @include fs15;
              @include futogo;
              color: $main;
              position: relative;
              z-index: 2;
            }
          }
          dd {
            @include fs(17,32);
            margin-top: 25px;
            @include mq(tbl) {
              margin-top: 0;
              @include fs15;
            }
          }
        }
      }
    }
  }
}