@import "variables";

@mixin pd {
  position: absolute;
  content: '';
  display: block;
}

@mixin mq($breakpoint: tb) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin sp_pc {
  &.sp {
    @include mq(tbl) {
      display: none;
    }
  }
  &.pc {
    display: none;
    @include mq(tbl) {
      display: block;
    }
  }
}
@mixin container($type:normal) {
  @if $type == normal {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(tbl) {
      padding-left: calc(50% - (#{$container} / 2));
      padding-right: calc(50% - (#{$container} / 2));
    }
  }
  @if $type == contents {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(tbl) {
      padding-left: calc(50% - (#{$container-contents} / 2));
      padding-right: calc(50% - (#{$container-contents} / 2));
    }
  }
  @if $type == wide {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(pc) {
      padding-left: calc(50% - (#{$container-wide} / 2));
      padding-right: calc(50% - (#{$container-wide} / 2));
    }
  }
  @if $type == narrow {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(tbl) {
      padding-left: calc(50% - (#{$container-narrow} / 2));
      padding-right: calc(50% - (#{$container-narrow} / 2));
    }
  }
}
@mixin containerNarrow {
  width: $containerNarrow;
  margin-right: auto;
  margin-left: auto;
}
@mixin hoverOp($op:0.5) {
  transition: opacity $transition-speed-fast ease;
  &:hover {
    opacity: $op;
    text-decoration: inherit;
  }
}
@mixin fs($fts, $lin: 14) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
}
@mixin slickdots {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 7px;
    @include mq(tbl) {
      margin: 0 10px;
    }
    button {
      border: none;
      background-color: #c9beaa;
      color: transparent;
      height: 7px;
      width: 7px;
      padding: 0;
      cursor: pointer;
      border-radius: 50%;
      @include mq(tbl) {
        height: 10px;
        width: 10px;
      }
    }
    &.slick-active {
      button {
        background-color: $main;
      }
    }
  }
}
@mixin slickarrow($size:default) {
  border: none;
  position: absolute;
  bottom: 30px;
  z-index: 3;
  color: transparent;
  padding: 0;
  cursor: pointer;
  width: 25px;
  height: 42px;
  @include mq(tbl) {
    @include hoverOp(.25);
    height: 60px;
    bottom: 50%;
    transform: translateY(50%);
  }
  &.slick-prev {
    background: center / contain no-repeat url(/images/prev.svg);
    left: 25px;
    @include mq(tbl) {
      @if $size == small {
        background: center / contain no-repeat url(/images/prev_bk.svg);
      }
    }
    @include mq(tbl) {
      left: 50px;
    }
  }
  &.slick-next {
    background: center / contain no-repeat url(/images/next.svg);
    right: 25px;
    @include mq(tbl) {
      @if $size == small {
        background: center / contain no-repeat url(/images/next_bk.svg);
      }
    }
    @include mq(tbl) {
      right: 50px;
    }
  }
}
@mixin button {
  display: block;
  background-color: $sub;
  color: $white;
  padding: 9px 15px;
  letter-spacing: .35em;
  margin-left: auto;
  margin-right: auto;
  @include fs(13,26);
  @include semibold;
  @include mq(tbl) {
    display: inline-block;
    @include fs(16,26);
    min-width: 220px;
    letter-spacing: 6px;
    padding: 15px 15px;
    text-align: center;
  }
}
@mixin futogo {
  font-family: "太ゴB101", "Futo Go B101";
}
@mixin bbb {
  font-family: "中ゴシックBBB", "Gothic Medium BBB";
}
@mixin en {
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin fs15 {
  @include fs(15,30);
}
@mixin fs20 {
  @include fs(20,37);
}
@mixin fs30 {
  @include fs(30,56);
}
@mixin fs38 {
  @include fs(38,64);
}
@mixin companybutton {
  margin-top: 35px;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-width: 435px;
    border: 1px solid $main;
    text-align: center;
    height: 55px;
    border-radius: 50px;
    color: $main;
    @include fs(12,12);
    transition: background  $transition-speed-fast $bezier, color  $transition-speed-fast $bezier;
    @include mq(tbl) {
      height: 76px;
      max-width: none;
      @include fs(18,18);
    }
    path {
      transition: fill  $transition-speed-fast $bezier;
    }
    @include mq(tbl) {
      &:hover {
        color: white;
        opacity: 1;
        background-color: $main;
        .logo {
          svg {
            .nitto_mark {
              fill: $white;
            }
            .nitto_type {
              path {
                fill: $white;
              }
            }
          }
          &.noster {
            svg {
              path {
                fill: $white;
              }
            }
          }
        }
        .icon {
          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }
    .logo {
      svg {
        height: 22px;
        @include mq(tbl) {
          height: 30px;
        }
      }
      &.noster {
        svg {
          height: 15px;
          @include mq(tbl) {
            height: 21px;
          }
        }
      }
    }
    .icon {
      svg {
        height: 14px;
      }
    }
  }
}
@mixin inview {
  opacity: 0;
  transform: translateY(50px);
  transition: transform 1s $bezier .5s, opacity 1s $bezier .5s;
  &.inview {
    opacity: 1;
    transform: translateY(0);
  }
}