/* compiled from /scss/_modules.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";

.n_lnav {
  background-color: #A9CACE;
  .inner {
    @include container(contents);
    ul {
      @include mq(tbl) {
        display: flex;
        gap: 50px;
      }
      li {
        &:not(.current) {
          display: none;
          @include mq(tbl) {
            display: block;
          }
        }
        &.current {
          a {
            @include mq(tbl) {
              opacity: 50%;
            }
          }
        }
        a {
          display: block;
          text-align: center;
          padding: 10px 0;
          color: white;
          @include fs(13,13);
          @include mq(tbl) {
            @include fs(14.5,14.5);
          }
        }
      }
    }
  }
}
.n_bnav {
  @include mq(tbl) {
    margin-top: 110px;
  }
  .inner {
    @include container(contents);
    @include mq(tbl) {
      padding-left: calc(50% - 345px);
      padding-right: calc(50% - 345px);
    }
    ul {
      @include mq(tbl) {
        display: flex;
        justify-content: center;
        gap: 63px;
      }
      li {
        width: 188px;
        margin: 50px auto 0;
        border-bottom: 2px solid $main-light;
        @include mq(tbl) {
          width: auto;
          max-width: 188px;
          flex: 1;
          margin: 0;
        }
        a {
          display: block;
          padding-bottom: 10px;
          text-align: center;
          color: $black;
          @include fs(18,18);
          @include mq(tbl) {
            padding-bottom: 13px;
          }
        }
        &.current {
          a {
            opacity: .5;
          }
        }
      }
    }
  }
}
.n_spnav{
  padding-top: 90px;
  @include mq(tbl) {
    display: none;
  }
  .inner {
    min-height: 90px;
    position: relative;
    a {
      display: block;
      @include fs(20,20);
      color: $main;
      @include futogo;
      position: absolute;
      top: 0;
      &::after {
        @include pd;
        width: 90px;
        height: 17px;
        background-size: contain;
        background-repeat: no-repeat;
        left: 0;
        bottom: 0;
      }
      &.prev {
        padding: 0 0 15px 40px;
        left: 27px;
        &::after {
          background-image: url(/images/prev.svg);
          background-position: left center;
        }
      }
      &.next {
        padding: 0 36px 15px 0;
        right: 27px;
        &::after {
          background-image: url(/images/next.svg);
          background-position: right center;
          right: 0;
          left: auto;
        }
      }
    }
  }
}