/* compiled from /scss/_modules.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";

.m_about_detail {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 100px;
  }
  .inner {
    @include container(contents);
    @include mq(tb) {
      padding-left: calc(50% - 345px);
      padding-right: calc(50% - 345px);
    }
    table {
      border-bottom: 1px solid $main-light;
      @include fs15;
      text-align: center;
      width: 100%;
      th {
        display: block;
        border-top: 1px solid $main-light;
        @include futogo;
        padding: 22px 0 2px;
        text-align: center;
        @include mq(tb) {
          display: table-cell;
          text-align: left;
          padding: 25px 50px;
          width: 35%;
        }
      }
      td {
        display: block;
        padding: 2px 0 22px;
        text-align: center;
        @include mq(tb) {
          border-top: 1px solid $main-light;
          display: table-cell;
          text-align: left;
          padding: 25px 50px;
          width: 65%;
        }
      }
    }
  }
}
.m_company {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  .inner {
    padding-left: 20px;
    padding-right: 20px;
    @include mq(tbl) {
      @include container(normal);
    }
    .unit {
      margin-top: 60px;
      @include mq(tbl) {
        margin-top: 100px;
        padding-left: 74px;
      }
      &:first-of-type {
        margin-top: 0;
      }
      .image {

      }
      .text {
        margin-top: 40px;
        padding: 0 10px;
        @include mq(tbl) {
          margin-top: 60px;
          padding: 0 114px 0 40px;
          display: flex;
          justify-content: space-between;
          gap: 80px;
        }
        h2 {
          @include fs20;
          @include futogo;
          @include mq(tbl) {
            flex: 1;
            @include fs30;
          }
        }
        .desc {
          @include mq(tbl) {
            flex: 1;
          }
          p {
            margin-top: 35px;
            @include fs15;
            @include mq(tbl) {
              margin-top: 0;
            }
          }
          .button {
            @include companybutton;
          }
        }
      }
    }
  }
}
.m_access {
  padding-top: 50px;
  text-align: center;
  @include mq(tbl) {
    padding-top: 90px;
  }
  .inner {
    @include mq(tbl) {
      @include container(contents);
    }
    h1 {
      @include futogo;
      @include fs20;
      @include mq(tbl) {
        @include fs30;
      }
    }
    address {
      margin-top: 35px;
      @include fs15;
      text-align: center;
      padding: 0 15px;
    }
    .photo {
      margin-top: 65px;
    }
    #gmap {
      height: 51vw;
      overflow: hidden;
      position: relative;
      margin-top: 70px;
      @include mq(tbl) {
        height: 534px;
      }
      iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 275px;
        height: 51vw;
        width: 100%;
        @include mq(tbl) {
          height: 100%;
        }
      }
    }
  }
}