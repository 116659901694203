$container: 1186px;
$container-contents: 1040px;
$container-narrow: 960px;
$container-wide: 1334px;

$main: #108B96;
$main-light: #85CBBF;
$main-dark: #108B96;
$white: #FFFFFF;
$bg:$main-light;
$red: #FF4D4D;
$sub: #D9DD54;
$black: #333333;
$gray: #808080;
$gray-light: #f2f2f2;

$bezier: cubic-bezier(.25,.1,.25,1);
$transition-speed: .5s;
$transition-speed-fast: .3s;

$pt: 60px;
$ps: 30px;

$shadow: drop-shadow(0px 0px 8px rgba(0, 0, 0, .8));

$narrow: 665px;

$imagePath: '/images/';

$breakpoints: (
  'sp': 'screen and (min-width: 580px)',
  'tb': 'screen and (min-width: 768px)',
  'tbl': 'screen and (min-width: 1080px)',
  'pc': 'screen and (min-width: 1334px)',
) !default;
