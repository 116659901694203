/* compiled from /scss/_modules.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";


.m_search_title {
  display: none;
  @include mq(tbl) {
    padding-top: 40px;
    display: block;
  }
  .inner {
    @include mq(tbl) {
      padding-left: calc(50% - 480px);
      padding-right: calc(50% - 480px);
    }
    h1 {
      @include futogo;
      @include fs20;
    }
  }
}
.m_search_list {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
  @include mq(tbl) {
    margin-top: 35px;
    padding-bottom: 120px;
    padding-left: calc(50% - 480px);
    padding-right: calc(50% - 480px);
  }
  .inner {
    background-color: $white;
    box-shadow: 0 0 12px rgba(169,202,206,.24);
    padding: 10px 18px 20px;
    @include mq(tbl) {
      padding: 20px 112px 30px;
    }
    ul {
      li {
        border-top: 1px solid $main-light;
        padding: 20px 4px;
        @include mq(tbl) {
          padding: 30px 20px 30px;
        }
        &:first-child {
          border-top: none;
        }
        .url {
          @include fs15;
          a {
            color: $black;
          }
        }
        h2 {
          margin-top: 5px;
          @include fs20;
          @include mq(tbl) {
            flex: 0 1 auto;
          }
        }
      }
    }
  }
}
.m_news_detail {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  @include mq(tbl) {
    margin-top: 35px;
    padding-left: calc(50% - 480px);
    padding-right: calc(50% - 480px);
  }
  .inner {
    background-color: $white;
    box-shadow: 0 0 12px rgba(169,202,206,.24);
    padding: 10px 18px 20px;
    @include mq(tbl) {
      padding: 30px 112px 30px;
    }
    .title {
      border-bottom: 1px solid $main-light;
      padding: 20px 5px 25px;
      @include mq(tbl) {
        padding: 20px 20px 30px;
      }
      .cat {
        a,span {
          display: inline-block;
          background-color: #A9CACE;
          border-radius: 4px;
          padding: 4px 14px;
          color: $white;
          @include fs(11,11);
          margin-right: 5px;
        }
      }
      .titleinner {
        color: $black;
        @include mq(tbl) {
          display: flex;
          gap: 25px;
        }
        .date {
          margin-top: 10px;
          @include fs15;
        }
        h2 {
          margin-top: 5px;
          @include fs20;
          @include mq(tbl) {
           flex: 0 1 auto;
          }
        }
      }
    }
    .content {
      padding: 25px 5px;
      @include mq(tbl) {
        padding: 40px 20px;
      }
      p {
        margin-top: 2em;
        @include fs15;
        &:first-of-type {
          margin-top: 0;
        }
      }
      img[class*="wp-image-"] {
        margin: 30px 0 10px;
      }
      a {
        text-decoration: underline;
      }
    }
  }
}
.m_news_nav {
  margin-top: 50px;
  @include mq(tbl) {
    margin-top: 50px;
    padding-bottom: 130px;
  }
  .inner {
    padding-left: 40px;
    padding-right: 40px;
    @include mq(tbl) {
      padding-left: calc(50% - 365px);
      padding-right: calc(50% - 365px);
    }
    .wp-pagenavi {
      position: relative;
      @include mq(tbl) {
        display: flex;
        justify-content: center;
        padding: 0 80px;
      }
      .pages {
        display: none;
      }
      .page, .current, .extend {
        display: none;
        @include mq(tbl) {
          display: block;
          color: $black;
          padding: 2px 4px;
          margin: 0 15px;
          @include fs15;
        }
      }
      .current {
        @include mq(tbl) {
          border-bottom: 1px solid $main-light;
        }
      }
      .previouspostslink {
        @include mq(tbl) {
          position: absolute;
          left: 0;
          top: 0;
        }
        span {
          display: inline-block;
          img {
            width: 20px;
            transform: rotate(180deg);
          }
        }
      }
      .nextpostslink {
        position: absolute;
        right: 0;
        top: 0;
        span {
          display: inline-block;
          img {
            width: 20px;
          }
        }
      }
    }
  }
}
.m_news_nav_detail {
  margin-top: 50px;
  padding-left: 40px;
  padding-right: 40px;
  @include mq(tbl) {
    margin-top: 50px;
    padding-bottom: 170px;
    padding-left: calc(50% - 365px);
    padding-right: calc(50% - 365px);
  }
  .inner {
    position: relative;
    @include fs(13,21);
    @include mq(tbl) {
      display: flex;
      justify-content: center;
      padding: 0 80px;
    }
    .prev {
      @include mq(tbl) {
        position: absolute;
        left: 0;
        top: 0;
      }
      a {
        display: inline-block;
        @include mq(tbl) {
          display: flex;
          align-items: center;
          gap: 20px;
          color: $black;
        }
        span {
          display: none;
          @include mq(tbl) {
            display: block;
          }
        }
        img {
          width: 20px;
          transform: rotate(180deg);
        }
      }
    }
    .next {
      position: absolute;
      right: 0;
      top: 0;
      a {
        display: inline-block;
        @include mq(tbl) {
          display: flex;
          align-items: center;
          gap: 20px;
          color: $black;
        }
        span {
          display: none;
          @include mq(tbl) {
            display: block;
          }
        }
        img {
          width: 20px;
        }
      }
    }
    .back {
      position: absolute;
      left: 50%;
      top: -5px;
      transform: translateX(-50%);
      @include mq(tbl) {
        top: 0;
      }
      a {
        display: inline-block;
        padding: 6px 0;
        border-bottom: 1px solid $main-light;
        color: $black;
      }
    }
  }
}