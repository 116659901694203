@charset "UTF-8";
@import "reset";
@import "variables";
@import "mixins";

* {
   box-sizing: border-box;
}
*:before,
*:after {
   box-sizing: border-box;
}
*:focus {
  outline: none;
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
input {
  margin: 0;
	padding: 0;
	background: none;
	border: none;
	border-radius: 0;
	outline: none;
	appearance: none;
}
html {
  font-size: 62.5%;
  height: 100%;
  height: -webkit-fill-available;
  scroll-behavior: smooth;
  @include mq(pc) {
    min-width: $container + 80px;
  }
}
body {
  @include fs(14);
  color: $black;
  @include bbb;
  background-color: $white;
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  letter-spacing: 0.05em;
  -webkit-font-smoothing: antialiased;
  position: relative;
  &::before {
    @include pd;
    z-index: -1;
    height: 1082px;
    width: 100%;
    background: center bottom / cover no-repeat url(/images/bg.png);
    top: 0;
    @include mq(tb) {
      height: 1082px;
      background-image: url(/images/bg_pc.png);
    }
  }
  &::after {
    @include pd;
    z-index: -2;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: $white;
  }
}
strong {
  font-weight: bold;
}
label {
  cursor: pointer;
}
input,select,textarea,label,a {
  outline: none;
}
a {
  color: $main;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  @include mq(tbl) {
    @include hoverOp();
  }
  &:hover {
    text-decoration: none;
  }
  &[href^="tel:"] {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
}
button {
  outline: none;
  cursor: pointer;
}
sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  position: relative;
  font-size: 0.75em;
}
sup {
  bottom: .75ex;
}
sub {
  top: .5ex;
}
img {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
main {
  min-height: 600px;
  @include mq(tb) {
    min-height: auto;
  }
  &.white {
    background-color: $white;
  }
}
.hidden-over-sp {
  @include mq(sp) {
    display: none !important;
  }
}
.hidden-over-tb {
  @include mq(tb) {
    display: none !important;
  }
}
.hidden-over-tbl {
  @include mq(tbl) {
    display: none !important;
  }
}
.hidden-over-pc {
  @include mq(pc) {
    display: none !important;
  }
}
.hidden-sp {
  @media (max-width: 579px) {
    display: none !important;
  }
}
.hidden-tb {
  @media (max-width: 767px) {
    display: none !important;
  }
}
.hidden-tbl {
  @media (max-width: 1079px) {
    display: none !important;
  }
}
.hidden-pc {
  @media (max-width: 1333px) {
    display: none !important;
  }
}
.container {
  @include container;
}
.svg_def {
  display: none;
}
.loader {
  margin: 50px auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #85cbbf;
  background: linear-gradient(to right, #85cbbf 10%, rgba(133,203,191, 0) 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
  &::before {
    width: 50%;
    height: 50%;
    background: #85cbbf;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  &::after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
@import "header";
@import "footer";
@import "modules";
@import "modules_about";
@import "modules_business";
@import "modules_recruit";
@import "modules_csr";
@import "modules_news";
@import "modules_contact";
@import "modules_privacy";
@import "modules_search";
@import "modules_top";
@import "navigation";
@import "top";
@import "animsition";
@import "slick";
// @import "fancybox";
