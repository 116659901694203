/* compiled from /scss/_header.scss */

@charset "UTF-8";
@import "variables";
@import "mixins";

header {
  padding: 38px 0 36px ;
  position: relative;
  letter-spacing: 0;
  @include mq(tbl) {
    padding: 50px 0 50px 0;
  }
  /*
  &::after {
    content: 'min';
    position: fixed;
    left: 10px;
    top: 10px;
    font-size: 20px;
    background-color: red;
    color: white;
    z-index: 1000;
    @include fs(12,12);
    padding: 2px 6px;
    border-radius: 2px;
    text-transform: uppercase;
    @include mq(sp) {
      content: 'sp';
    }
    @include mq(tb) {
      content: 'tb';
    }
    @include mq(tbl) {
      content: 'tbl';
    }
    @include mq(pc) {
      content: 'pc';
    }
  }
  */
  .container {
    @include container(normal);
    position: relative;
    .top {
      @include mq(tbl) {
        display: flex;
        justify-content: space-between;
        padding-left: 74px;
        padding-right: 74px;
      }
      @include mq(pc) {
        padding-right: 0;
      }
      #logo {
        a {
          @include mq(tbl) {
            @include hoverOp;
          }
          img {
            width: 265px;
            @include mq(tbl) {
              width: 282px;
            }
          }
        }
      }
      .util {
        display: none;
        @include mq(tbl) {
          display: flex;
          gap: 27px;
        }
        li {
          @include fs(10,10);
          &.en {
            display: none;
          }
          a {
            color: #4d4d4d;
          }
        }
      }
    }
    .bottom {
      display: none;
      @include mq(tbl) {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        padding-left: 74px;
        padding-right: 74px;
      }
      @include mq(pc) {
        padding-right: 0;
      }
      ul {
        display: flex;
        gap: 48px;
        li {
          @include fs(14,14);
          a {
            color: $black;
            @include hoverOp;
            &.current {
              @include mq(tbl) {
                opacity: .4;
              }
            }
          }
        }
      }
      .search {
        .searchinner {
          border: 1px solid #ddd;
          padding: 2px;
          background-color: $white;
          input {
            width: 181px;
            @include fs(13,13);
            vertical-align: bottom;
          }
          button {
            img {
              width: 21px;
            }
          }
        }
      }
    }
  }
}
#covernav {
  @include mq(tbl) {
    display: none;
  }
  #menuButton {
    position: fixed;
    right: 18px;
    top: 22px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    z-index: 1002;
    @include mq(tbl) {
      display: none;
    }
    span {
      background-color: #B3B3B3;
      width: 24px;
      height: 1px;
      position: absolute;
      left: 12px;
      &.top {
        top: 16px;
        transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
      }
      &.middle, &.back {
        top: 23px;
        transform-origin: center;
        transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
      }
      &.bottom {
        top: 30px;
        transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
      }
    }
    &.show {
      span {
        background-color: $white;
        &.top {
          transform: translateX(35%);
          background-color: transparent;
        }
        &.bottom {
          transform: translateX(-35%);
          background-color: transparent;
        }
        &.middle {
          transform: rotate(35deg) scaleX(1);
          transition: transform $transition-speed-fast $bezier $transition-speed-fast;
        }
        &.back {
          transform: rotate(-35deg) scaleX(1);
        }
      }
    }
  }
  #gnav {
    display: none;
    background-color:#4d4d4d;
    color: $white;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 998;
    overflow: scroll;
    @include mq(tbl) {
      display: none;
    }
    .inner {
      padding: 38px 20px 0;
      letter-spacing: 0;
      .logo {
        img {
          width: 265px;
        }
      }
      a {
        color: $gray-light;
      }
      h2 {
        margin-top: 50px;
        @include fs(17,17);
        @include futogo;
        a {
          padding: 0 0 17px;
          display: block;
        }
      }
      .main {
        border-top: 1px solid rgba(242,242,242,.5);
        @include fs(13,21);
        > li {
          border-bottom: 1px solid rgba(242,242,242,.5);
          a {
            display: block;
            padding: 14px 3px;
            position: relative;
            &::after {
              @include pd;
              width: 7px;
              height: 11px;
              background: center / contain no-repeat url(/images/arrow_gnav.svg) ;
              right: 7px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          &.haschild {
            display: flex;
            > span {
              flex: 1;
              display: block;
              padding: 14px 3px;
              &::after {
                content: none;
              }
            }
            .child {
              flex: 1;
              li {
                a {
                  display: block;
                  padding: 14px 3px;
                }
              }
            }
          }
        }
      }
      .sub {
        margin-top: 34px;
        li {
          @include fs(13,21);
          a {
            display: block;
            padding: 11px 3px 11px 24px;
            position: relative;
            &::before {
              @include pd;
              width: 15px;
              height: 1px;
              background-color: rgba(242,242,242,.5);
              top: 50%;
              left: 0;
            }
          }
          &.en {
            display: none;
          }
        }
      }
      .copyright {
        margin-top: 30px;
        text-align: center;
        @include fs(9,9);
        @include en;
        padding-bottom: 50px;
      }
    }
  }
}