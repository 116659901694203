/* compiled from /scss/_modules.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";

.m_top_mv {
  height: calc(100vh - 90px);
  height: calc(100svh - 90px);
  position: relative;
  padding-left: 14vw;
  @include mq(tb) {
    height: 535px;
    padding-left: 50vw;
  }
  #slide_pc {
    display: none;
    opacity: 0;
    transition: opacity 2s ease;
    &.slick-initialized {
      opacity: 1;
    }
    .slick-slide {
      height: 535px;
      background-position: center;
      background-size: cover;
    }
    @include mq(tb) {
      display: block;
    }
  }
  #slide_sp {
    z-index: 1;
    opacity: 0;
    transition: opacity 2s ease;
    &.slick-initialized {
      opacity: 1;
    }
    @include mq(tb) {
      display: none;
    }
    .slick-slide {
      height: calc(100vh - 90px);
      height: calc(100svh - 90px);
      background-position: center;
      background-size: cover;
    }
  }
  .text {
    background-color: rgba(255,255,255,.9);
    position: absolute;
    left: 20px;
    bottom: 60px;
    padding: 25px 23px;
    z-index: 2;
    opacity: 0;
    transition: opacity 2s ease;
    &.loaded {
      opacity: 1;
    }
    @include mq(tb) {
      left: 30px;
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
      padding: 35px 33px;
    }
    @include mq(tbl) {
      padding: 40px 20px 40px 40px;
      left: 120px;
      letter-spacing: 3px;
    }
    @include mq(pc) {
      left: 50%;
      transform: translate(-520px,-50%);
    }
    h1 {
      @include fs(18,30);
      @include futogo;
      color: $main;
      @include mq(tb) {
        @include fs(25,50);
      }
      @include mq(tbl) {
        @include fs(35,62);
      }
    }
  }
}
.m_top_news {
  margin-top: 50px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  .inner {
    padding: 0 20px;
    @include mq(tbl) {
      @include container(narrow);
    }
    h2 {
      text-align: center;
      @include fs15;
      span {
        display: inline-block;
        border-bottom: 2px solid $main-light;
        padding: 0 10px 2px;
      }
    }
    ul {
      margin-top: 55px;
      li {
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
        a {
          display: block;
          @include mq(tbl) {
            display: flex;
            gap: 20px;
            align-items: flex-start;
          }
          .date {
            @include fs(10,10);
            border-bottom: 1px solid #e6e6e6;
            display: inline-block;
            padding: 0 12px 8px;
            color: #808080;
            white-space: nowrap;
            @include mq(tbl) {
              margin-top: 10px;
            }
          }
          h3 {
            margin-top: 10px;
            @include fs(13,30);
            color: $black;
            @include mq(tbl) {
              flex: 0 1 auto;
              margin-top: 0;
            }
          }
        }
      }
    }
    .list {
      text-align: center;
      margin-top: 40px;
      a {
        display: inline-block;
        @include fs(13,13);
        border-bottom: 1px solid #e6e6e6;
        padding: 0 22px 10px;
        color: $black;
      }
    }
  }
}
.m_top_company {
  margin-top: 100px;
  .inner {
    @include container(narrow);
    h2 {
      text-align: center;
      @include fs15;
      span {
        display: inline-block;
        border-bottom: 2px solid $main-light;
        padding: 0 30px 2px;
      }
    }
    ul {
      @include mq(tb) {
        display: flex;
        gap: 14px;
        margin-top: 50px;
      }
      li {
        margin-top: 50px;
        @include mq(tb) {
          margin-top: 0;
          flex: 1;
        }
        a {
          border: 1px solid #E6E6E6;
          border-radius: 0 0 20px 20px;
          border-top-width: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 78px;
          background-color: $white;
          @include mq(tbl) {
            height: 116px;
          }
          &.nitto {
            border-top-color: #1A9FE2;
            img {
              width: 187px;
              @include mq(tbl) {
                width: 277px;
              }
            }
          }
          &.noster {
            border-top-color: #183670;
            img {
              width: 131px;
              @include mq(tbl) {
                width: 193px;
              }
            }
          }
        }
      }
    }
  }
}